import http from "../http";
import Service from "../../Service";

/**
 * @type DirectPurchaseService
 */
export default class DirectPurchaseService extends Service {

    load(params) {
        return http.get(`admin/providers/orders`, {
            headers: this.getHeaders(),
            params
        });
    }

    update(id, params) {
        return http.put(`admin/providers/products/${id}`, params, {
            headers: this.getHeaders()
        });
    }

    activate(id) {
        return http.put(`admin/providers/products/${id}/activate`, {}, {
            headers: this.getHeaders()
        });
    }

    deactivate(id) {
        return http.put(`admin/providers/products/${id}/deactivate`, {}, {
            headers: this.getHeaders()
        });
    }

    remove(id) {
        return http.delete(`admin/providers/products/${id}`, {
            headers: this.getHeaders()
        });
    }
}
