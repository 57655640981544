<style lang="scss" scoped>
	@import "./manage-categories.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow p-4">
				<div class="col-12">
					<label for="" class="label-control">Minhas Categorias</label>
					<div class="col-12 mb-3">
						<ValidationObserver v-slot="{ handleSubmit }">
							<div class="row">
								<div class="col-2 px-2">
									<ValidationProvider rules="required" v-slot="v">
										<cc-input :placeholder="'Cod. Interno'" :theme="'theme-green'" class="mt-1" v-model="new_category.cat_codInterno" />
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
								<div class="col p-0">
									<ValidationProvider rules="required" v-slot="v">
										<cc-search :placeholder="'Nova Categoria'" :icon="'fa success fa-plus'" :theme="'theme-green'" class="mt-1" v-model="new_category.cat_descricao" @search="handleSubmit(create)" />
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
						</ValidationObserver>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">#ID</th>
									<th class="text-left px-3">Cod. Interno</th>
									<th class="text-left px-0">Nome</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="cat in categories" :key="cat.id" :class="{ selected: cat.selected }">
									<td class="text-left px-3"><i v-show="cat.selected" class="fas fa-hand-point-right mr-2 theme-blue"></i><b>#{{ cat.cat_id }}</b></td>
									<td class="text-left pl-4">
										{{ cat.cat_codInterno }}
									</td>
									<td class="text-left px-0">
										<cc-input v-model="cat.cat_descricao" :readonly="!cat.edit" />
									</td>
									<td class="text-center">
										<i v-if="!cat.edit" class="fa fa-pencil remove-btn" @click="select(cat)" />
										<i v-else class="fa fa-floppy-o remove-btn theme-green" @click="update(cat)" />
										<i v-show="!cat.loading" class="fa fa-trash remove-btn" @click.stop="remove(cat)" />
										<cc-loader v-show="cat.loading" :show_txt="false" class="loader float-right" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</cc-modal>
</template>

<script>

import CategoryService from "@/services/v1/category.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},
	mixins: [ loaderMixin ],
  	data() {
		return {
			categories: [],
			category: {},
			new_category: {},
			page: 1,
			filters: {
				name: '',
				total_items : 0,
				per_page: 0
			},
			loading: false,
			service: new CategoryService(),
			modal: {
				title: `gerenciar categorias de produtos`,
				subtitle: `Gerencie categorias de seus produtos para melhor organização de seu catálogo`,
				icon_title: 'fas fa-tags',
				cancel_text: 'Voltar',
				style: {
					width: "70%"
				}
			},
		};
	},
  	methods: {
		select(cat) {
			this.categories.forEach(c => c.edit = false)
			cat.edit = true
			this.$forceUpdate()
		},
		update(cat) {
			this.present_loader("Salvando categoria...")
			this.service.update(cat).then((response) => response.data)
			.then((data) => this.load_categories())
			.then(this.dismiss_loader)
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
				if(error.response.data && error.response.data) {
					this.present_error(error.response.data.mensagem)
				}
			});
		},
		create() {
			this.present_loader("Salvando categoria...")
			this.service.create(this.new_category)
			.then((response) => response.data)
			.then((data) =>  this.load_categories())
			.then(this.dismiss_loader)
			.then(() => this.new_category = {})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
				if(error.response.data && error.response.data) {
					this.present_error(error.response.data.mensagem)
				}
			});
		},
		remove(cat) {
			this.confirm_action({
				message: "Confirma remoção da categoria?",
				subtitle: `Categoria #${cat.cat_descricao} será removida!`,
				callback: () => {
					this.$set(cat, 'loading', true)
					this.service.delete(cat.cat_id)
					.then(() => this.load_categories()).then(() => this.$set(cat, 'loading', false))
				}
			})
		},
		close() {
			this.$emit("close");
		},
		load_categories() {
			this.present_loader("Carregando suas categorias...")
			return this.service.all()
			.then(response => response.data)
			.then(data => {
				this.categories = data.categorias;
				this.dismiss_loader()
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		}
	},
	mounted() {
		this.load_categories()
	}
};
</script>
