<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Venda Direta
      </div>
    </div>
    <div class="page-title">
      Venda Direta
    </div>
    <div class="orders-page__actions-container">
      <div class="orders-page__left-content">
        <StandardInput :placeholder="'Digite o ID'" 
                       :title="'ID'" 
                       :action="setIdValue" 
                       :value="filters.id"
                       class="orders-page__left-content-inputs"
                       :type="'text'"/>
        <StandardInput :placeholder="'Digite a empresa'" 
                       :title="'Empresa'" 
                       :action="setNameValue" 
                       :value="filters.name"
                       class="orders-page__left-content-inputs"
                       :type="'text'"/>
        <!-- <div class="orders-page__actions-wrapper">
          <div class="orders-page__input-title-wrapper">
            <span class="orders-page__input-title">ID</span>
          </div>
          <div class="orders-page__product-input-container">
            <input @keyup.enter="list(filters.id)" v-model="filters.id" class="orders-page__product-input"  type="text" placeholder="Digite o ID">
            <span @click="list(filters.id)" class="material-icons-outlined search-icon">
              search
            </span>
          </div>
        </div> -->
        <!-- <div class="orders-page__actions-wrapper">
          <div class="orders-page__input-title-wrapper">
            <span class="orders-page__input-title">Empresa</span>
          </div>
          <div class="orders-page__product-input-container">
            <input @keyup.enter="list(filters.name)" v-model="filters.name" class="orders-page__product-input"  type="text" placeholder="Digite o nome da empresa">
            <span @click="list(filters.name)" class="material-icons-outlined search-icon">
              search
            </span>
          </div>
        </div> -->

        
        
        <div v-if="!filters.with_campaign" @click="() => { toogleFilter(1); list() }" class="lastbuydate_wrapper">
          <span class="material-icons-outlined">check_box_outline_blank</span>
          <span style="width: max-content;">Pedidos de campanha</span>
        </div>
        <div v-if="filters.with_campaign" @click="() => { toogleFilter(0); list() }" class="lastbuydate_wrapper">
          <span class="material-icons-outlined">check_box</span>
          <span style="width: max-content;">Pedidos de campanha</span>
        </div>
      </div>

      <Button class="action-buttons" :iconName="'inventory_2'" title="Produtos" :text="'Produtos'" :action="() => { $router.push('/fornecedores/produtos') }" />
      
      <Button style="margin-top: 1.5em;" class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters" />
      
    </div>
    <div class="page-selected-filters-container">
	    <div v-if="filters.id" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.id }}</div>
        <span @click="setIdValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.name }}</div>
        <span @click="setNameValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="is_loading" class="default" />
    <no-data-available v-if="orders.length == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading" class="direct-page-table-container">
      <div v-show="orders.length > 0">
        <div class="page-table-header">
          <div class="page-table-header-text id-width">ID</div>
          <div class="page-table-header-text company-width">Empresa</div>
          <div class="page-table-header-text company-width">Fornecedor</div>
          <div class="page-table-header-text date-width">Data</div>
          <div class="page-table-header-text value-width">Valor</div>
          <div class="page-table-header-text delivery-width">Entrega</div>
          <div class="page-table-header-text receive-type-width">Tipo Recebimento</div>
          <div class="page-table-header-text status-width">Status</div>
          <div class="page-table-header-text seller-width">Vendedor</div>
          <div class="page-table-header-text campaign-width">Campanha</div>
          <div class="page-table-header-text integration-width">Integração</div>
        </div>
        <div v-for="(order, idx) in orders"
             :key="'prod-' + order.id"
             :class="getSpecialBackground(idx)">
          <div class="page-table-row">
            <div class="page-table-desc-column id-width"><div class="page-table-header-mobile">ID</div>{{ order.id }}</div>
            <div class="page-table-desc-column company-width"><div class="page-table-header-mobile">Empresa</div>{{ order.store ? order.store.name : '-' }}</div>
            <div class="page-table-desc-column company-width"><div class="page-table-header-mobile">Fornecedor</div>{{ order.provider ? order.provider.name : '-' }}</div>
            <div class="page-table-desc-column date-width"><div class="page-table-header-mobile">Data</div>{{ order.created_at | formatDate }}</div>
            <div class="page-table-desc-column value-width"><div class="page-table-header-mobile">Valor</div>{{ getValue(order) | currency }}</div>
            <div class="page-table-desc-column delivery-width"><div class="page-table-header-mobile">Entrega</div>{{ order.delivery_date | formatDate }}</div>
            <div class="page-table-desc-column receive-type-width"><div class="page-table-header-mobile">Tipo Recebimento</div>{{ order.delivery_type || '-'}}</div>
            <div class="page-table-desc-column status-width"><div class="page-table-header-mobile">Status</div>{{ order.status }}</div>
            <div class="page-table-desc-column seller-width"><div class="page-table-header-mobile">Vendedor</div>{{ order.seller ? order.seller.name : '-' }}</div>
            <div class="page-table-desc-column campaign-width"><div class="page-table-header-mobile">Campanha</div>{{ order.campaign ? order.campaign.name : '-'}}</div>
            <div class="page-table-desc-column integration-width">
              <div class="page-table-header-mobile">Integração</div>
              <div style="display: flex;">
                <span v-if="!order.integrations || order.integrations.length == 0 || order.integrations[0].status == 'FAILED'" title="Não integrado" class="material-icons-outlined warning-icon">
                  warning
                </span>
                <span v-else title="Integrado" class="material-icons-outlined check-icon">
                  check_circle
                </span>
                <router-link :to="'/cliente/monitores/pedidos/'+order.id" v-if="filters.with_campaign" class="material-icons-outlined check-icon">visibility</router-link>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <tr style="display: flex; justify-content: center;" v-show="total_items > items_by_page_value">
        <td class="text-center" colspan="10">
          <Paginacao classes="orange justify-end"
                     :items_by_page_value="items_by_page_value"
                     :total="total_items"
                     :page="page"
                     v-on:change="list($event)">
          </Paginacao>
        </td>
      </tr>
    </div>
    <cc-product-brands-modal v-if="is_brands_modal_open && current_product"
                             :product="current_product"
                             @close="is_brands_modal_open = false;current_product = null" />
    <cc-client-categories v-if="manage_categories" @close="manage_categories = false" />
    <edit-product-modal v-if="is_edit_modal_open && current_product"
                        :product="current_product"
                        :categories="categories"
                        @reload="list"
                        @close="is_edit_modal_open = false;current_product = null" />
  </div>
    <!-- <div class="monitors">
        <div class="body">
            <div class="container-fluid mb-5">
                <div class="row py-3">
                    <div class="col">
                        <form @submit.prevent="list()">
                            <div class="row">
                                <div class="col col-2">
                                    <label for="" class="label-control">ID</label>
                                    <cc-search v-model="filters.id" name="id" :noicon="true" />
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">Empresa</label>
                                    <cc-search v-model="filters.name" name="name" />
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-checkbox
                                        class="mt-1"
                                        @change="(evt) => { filters.with_campaign = evt.target.checked;list() }"
                                        :label="'Pedidos de campanha'"
                                        :checked="filters.with_campaign" />
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed secondary'"
                                        :icon="'fas fa-boxes'"
                                        :content="'Produtos'"
                                        :link="'/fornecedores/produtos'" />
                                </div>
                                <button type="submit" style="visibility: hidden"></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 p-4 shadow">
                        <no-data-available v-if="orders.length == 0" :text="'Nenhum pedido encontrado'" />
                        <table v-show="orders.length > 0" class="table">
                            <thead>
                                <tr>
                                    <th class="pl-4">ID</th>
                                    <th>Empresa</th>
                                    <th>Data</th>
                                    <th>Valor</th>
                                    <th>Entrega</th>
                                    <th>Tipo Recebimento</th>
                                    <th>Status</th>
                                    <th>Vendedor</th>
                                    <th>Campanha</th>
                                    <th>Integração</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="order in orders" >
                                    <tr :key="'prod-' + order.id">
                                        <td class="pl-4">{{ order.id }}</td>
                                        <td>{{ order.store ? order.store.name : '-' }}</td>
                                        <td>{{ order.created_at | formatDate }}</td>
                                        <td>{{ order.created_at | formatDate }}</td>
                                        <td>{{ order.delivery_date | formatDate }}</td>
                                        <td>{{ order.delivery_type }}</td>
                                        <td>{{ order.status }}</td>
                                        <td>{{ order.seller ? order.seller.name : '-' }}</td>
                                        <td>{{ order.campaign ? order.campaign.name : '-'}}</td>
                                        <td>
                                            <i  v-if="!order.integrations || order.integrations.length == 0 || order.integrations[0].status == 'FAILED'"
                                                class="fa fa-exclamation-triangle integration-status failed"
                                                title="Não integrado"
                                                aria-hidden="true"></i>
                                            <i  v-else
                                                title="Integrado"
                                                class="fa fa-check-circle-o integration-status success"
                                                aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-show="total_items > items_by_page_value">
                                    <td class="text-center" colspan="10">
                                        <Paginacao
                                            classes="orange justify-end"
                                            :items_by_page_value="items_by_page_value"
                                            :total="total_items"
                                            :page="page"
                                            v-on:change="list($event)"
                                        ></Paginacao>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <cc-product-brands-modal
            v-if="is_brands_modal_open && current_product"
            :product="current_product"
            @close="is_brands_modal_open = false;current_product = null" />
        <cc-client-categories v-if="manage_categories" @close="manage_categories = false" />
        <edit-product-modal
            v-if="is_edit_modal_open && current_product"
            :product="current_product"
            :categories="categories"
            @reload="list"
            @close="is_edit_modal_open = false;current_product = null" />
    </div> -->
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import ProductModal from "@/modals/new-product/new-product.modal";
import ManageCategoriesModal from "@/modals/client/categories/manage-categories.modal";
import DirectPurchaseService from '@/services/v3/providers/direct-purchase.service';
import CategoryService from '@/services/v3/management/category.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService"
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    mixins: [ loaderMixin ],
    components: {
        Paginacao,
        'edit-product-modal': ProductModal,
        'cc-product-brands-modal': ProductBrandsModal,
        'cc-client-categories': ManageCategoriesModal,
        Button,
        StandardInput,
    },
    data(){
        return {
            is_loading: false,
            //loading: false,
            page: 1,
            orders: [],
            filters: {},
            categories: [],
            items_by_page_value: 30,
            total_items: 1000,
            brands: null,
            current_product: null,
            manage_categories: false,
            svc: new DirectPurchaseService(),
            cat_svc: new CategoryService(),
            show_add_product_modal: false,
            is_brands_modal_open: false,
            is_edit_modal_open: false,
            options: [
                {
                    label: "Novo Produto",
                    action: () => { this.show_add_product_modal = true }
                }
            ],
            breadcrumbs: [
                {
                    name: "Produtos Padrão",
                    route: 'products'
                }
            ]
        }
    },
    /* watch: {
      filters : {
        handler: function() {
          setTimeout(() => {
            this.list(1)      
          }, 600);
        },
        deep: true
      }
    }, */
    methods:{
      toogleFilter(value){
        this.filters.with_campaign = value
        this.$store.commit('SET_DIRECT_SALE', value)
      },
      getValue(order){
        let result = 0
        for (let index = 0; index < order.items.length; index++) {
          const element = order.items[index];
          result += element.wrap_price * element.quantity
        }
        return result
      },
      setIdValue(value){
		    this.filters.id = value
        this.list(this.page)
		  },
      setNameValue(value) {
        this.filters.name = value
        this.list(this.page)
      },
      getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
      clearFilters() {
        this.filters = {}
        this.list(this.page)
      },
        toggle_highlight(val, product) {
            this.svc.highlight(product.id, val).then(() => {
                product.is_highlighted = val
            })
        },
        list(page = this.page) {
          this.is_loading = true;
            const params = {
                name: this.filters.name,
                category_id: this.filters.category?.id,
                page: page,
                id: this.filters.id,
                with_campaign: this.filters.with_campaign ? 1 : 0
            };
            this.svc.load(params).then(response => response.data).then(data => {
                this.is_loading = false;
                this.orders = data.data
                this.total_items = data.meta.total
            }).catch(error => {
				ErrorHandlerService.handle( error, this.$store );
			});
        },
        list_categories() {
            this.cat_svc.list().then((response) => response.data).then((data) => {
                this.categories = data
            })
        },
        edit(product) {
            this.current_product = product
            this.is_edit_modal_open = true
        },
        open_brands(product){
            this.is_brands_modal_open = true
            this.current_product = product
        },
        delete_product(product){
            this.confirm_action({
				message: "Confirma a remoção do produto?",
				subtitle: `Produto será excluído da base padrão!`,
				callback: () => {
					this.svc.delete(product.id)
                    .then(()=>{
                        this.list(1);
                        this.present_success("Excluído!", "Produto excluído com sucesso");
                    }).catch(error => {
                        ErrorHandlerService.handle( error, this.$store );
                    })
				}
			})
        }
    },
    mounted() {
        this.list()
        this.list_categories()
        this.filters.with_campaign = this.$store.getters.direct_sale
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import './index.page';
    

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.page-redirect-container {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0em;
}
.orders-page__actions-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2vh;
}
.orders-page__left-content {
  display: flex;
  align-items: flex-end;
  width: 60%;
}
.orders-page__input-title-wrapper {
  padding-bottom: 5px;
}
.orders-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}
.orders-page__right-content {}
.orders-page__product-input-container {
  display: flex;
  align-items: center;
}
.orders-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}
.orders-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
  cursor: pointer;
}
.page-table-header-mobile{
  display: none;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.lastbuydate_wrapper {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #898989;
  gap: 1vw;
}
.products-page__checkbox-wrapper {
  display: flex;
}
/* The container */
.products-page__checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1.7em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #898989;
}

/* Hide the browser's default checkbox */
.products-page__checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2.5px solid #ff7110;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.products-page__checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.products-page__checkbox-wrapper input:checked ~ .checkmark {
  background-color: var(--primary-color);
  border: 1px solid #ff7110;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.products-page__checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.products-page__checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.direct-page-table-container {
  margin: 3em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.id-width {
  width: 5%;
}
.company-width {
  width: 10%;
}
.date-width {
  width: 8%;
}
.value-width {
  width: 10%;
}
.delivery-width {
  width: 10%;
}
.receive-type-width {
  width: 10%;
}
.status-width {
  width: 20%;
}
.seller-width {
  width: 10%;
}
.campaign-width {
  width: 10%;
}
.integration-width {
  width: 7%;
  display: flex;
}
.warning-icon {
  display: flex;
  justify-content: center;
  color: #CD2A2A;
}
.check-icon {
  display: flex;
  justify-content: center;
  color: #187C3B;
}
.orders-page__left-content-inputs{
  width: 45%;
  margin-right: 5%;
}
.page-selected-filters-container {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .page-container{font-size: 1.25vw; margin-right: 3vw;}
  .integration-width{width: 10%;}
}
@media only screen and (max-width: 630px) {
  .page-container{font-size: 2vw;}
  .page-table-header{    display: none;  }
  .page-table-row{flex-direction: column; font-size: 1.3em;}
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
  }
  .id-width {
    width: 100%;
  }
  .company-width {
    width: 100%;
  }
  .date-width {
    width: 100%;
  }
  .value-width {
    width: 100%;
  }
  .delivery-width {
    width: 100%;
  }
  .receive-type-width {
    width: 100%;
  }
  .status-width {
    width: 100%;
  }
  .seller-width {
    width: 100%;
  }
  .campaign-width {
    width: 100%;
  }
  .integration-width {
    width: 100%;
  }
  .page-table-header-mobile{
    display: flex;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
  }
  .orders-page__left-content{
    flex-direction: column;
    gap: 3vh;
    width: 100%;
  }
  .lastbuydate_wrapper{
    margin-bottom: 2vh;
  }
  .orders-page__actions-container{
    flex-direction: column;
  }
  .action-buttons{
    width: 100%;
  }
  .orders-page__left-content-inputs{
    width: 100%;
    margin-right: 0;
  }
}
@media only screen and (max-width: 550px){
  .page-container{font-size: 2.5vw;}
}
@media only screen and (max-width: 450px){
  .page-container{font-size: 3.5vw;}
}
</style>
